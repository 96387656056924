import { Control, Required } from "@recursyve/ngx-form-generator";

export class SignInAuthenticationForm {
    @Control()
    @Required()
    email: string;

    @Control()
    @Required()
    password: string;
}
