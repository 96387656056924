import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { EurofinsSynchronizedAnalysisResults } from "./eurofins-synchronized-analysis-results.model";
import { EurofinsTests } from "./eurofins-tests.model";
import { EurofinsAnalysisCertificates } from "./eurofins-analysis-certificates.model";
import { EurofinsPurchaseOrders } from "./eurofins-purchase-orders.model";

export class EurofinsDataPoints extends DatabaseEntities {
    purchaseOrderId?: number;
    equipmentId?: number;
    legacyId?: string;
    projectNumber?: string;
    projectName?: string;
    nature?: string;
    configuredAt?: Date;
    suppressConfigurationNotification?: boolean;

    purchaseOrder?: EurofinsPurchaseOrders;
    tests?: EurofinsTests[];
    analysisCertificates?: EurofinsAnalysisCertificates[];
    synchronizedAnalysisResults?: EurofinsSynchronizedAnalysisResults[];

    public getPendingConfigurationCount(): number {
        if (this.suppressConfigurationNotification) {
            return 0;
        }

        let count = 0;

        if (!this.configuredAt) {
            count++;
        }

        if (this.tests) {
            for (const test of this.tests) {
                if (!test.configuredAt && !test.suppressConfigurationNotification) {
                    count++;
                }
            }
        }

        return count;
    }
}
