import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { GeneratedFormGroup, NgxFormGeneratorProvider } from "@recursyve/ngx-form-generator";
import { SignInAuthenticationForm } from "./sign-in-authentication.form";
import { SignInAuthenticationQuery } from "./store/sign-in-authentication.query";
import { SignInAuthenticationService } from "./store/sign-in-authentication.service";
import { AuthProviders } from "../../../api/nordicite/organizations/models/organizations.model";
import { Subject, switchMap, takeUntil } from "rxjs";
import { KeyboardCodes, niceAnimations } from "@recursyve/nice-ui-kit.v2";
import { ActivatedRoute, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
    selector: "nordicite-sign-in-authentication",
    templateUrl: "sign-in-authentication.template.html",
    styleUrls: ["sign-in-authentication.style.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: niceAnimations,
    providers: NgxFormGeneratorProvider.forFeature([SignInAuthenticationForm])
})
export class SignInAuthenticationComponent implements OnInit, OnDestroy {
    public AuthProviders = AuthProviders;
    public subTitlePath = "";
    public forceUserNamePassword = false;

    public loading$ = this.signInAuthQuery.selectLoading();
    public provider$ = this.signInAuthQuery.selectAuthProvider();
    public error$ = this.signInAuthQuery.selectError();

    private unsubscribeAll$ = new Subject<void>();

    constructor(
        public formGroup: GeneratedFormGroup<SignInAuthenticationForm>,
        private signInAuthQuery: SignInAuthenticationQuery,
        private signInAuthService: SignInAuthenticationService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    @HostListener("keyup", ["$event"])
    public onChange(event: KeyboardEvent) {
        if (event.code === KeyboardCodes.Enter) {
            this.clickSignIn();
        }
    }

    public async ngOnInit(): Promise<void> {
        this.provider$.pipe(takeUntil(this.unsubscribeAll$)).subscribe(provider => {
            switch (provider) {
                case AuthProviders.Password: {
                    this.subTitlePath = "features.sign_in.authentication.password_sub_title";
                    break;
                }
                case AuthProviders.Google: {
                    this.subTitlePath = "features.sign_in.authentication.google_sub_title";
                    break;
                }
                case AuthProviders.Microsoft: {
                    this.subTitlePath = "features.sign_in.authentication.microsoft_sub_title";
                    break;
                }
                default: {
                    this.subTitlePath = "";
                    break;
                }
            }
        });

        this.signInAuthService
            .init(this.activatedRoute.snapshot.queryParamMap.get("code"))
            .pipe(
                filter(({ isAuthenticated }) => isAuthenticated),
                switchMap(() => this.router.navigate(["/dashboard"]))
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
        this.signInAuthService.reset();
    }

    public validateCredentialsForm(): boolean {
        if (this.formGroup.valid) {
            return true;
        }
        this.formGroup.markAllAsTouched();
        return false;
    }

    public clickSignIn(): void {
        this.signInAuthService.resetError();

        const { authProvider, authConnection } = this.signInAuthQuery.getValue();

        if (this.forceUserNamePassword || authProvider === AuthProviders.Password) {
            if (this.validateCredentialsForm()) {
                const { email, password } = this.formGroup.getRawValue();
                this.signInAuthService.signInWithCredentials(email, password).subscribe(hasAccess => {
                    if (hasAccess) {
                        this.router.navigate(["/dashboard"]);
                    } else {
                        this.router.navigate(["sign-in"]);
                    }
                });
            }

            return;
        }

        if (!authConnection) {
            return;
        }

        this.signInAuthService.signInWithConnection(authConnection).subscribe();
    }

    public clickUsePassword(): void {
        this.forceUserNamePassword = true;
    }

    public reset(): void {
        this.signInAuthService.reset();
    }

    public resetPassword(): void {
        this.router.navigate(["forgot-password"]);
    }
}
