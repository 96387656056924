import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { Accounts } from "../../../api/nordicite/accounts/models/accounts.model";

export interface ResetPasswordState {
    code: string | null;
    codeInvalid: boolean;
    passwordUpdated: boolean;
    account: Accounts | null;
}

const initialState: ResetPasswordState = {
    code: null,
    codeInvalid: false,
    passwordUpdated: false,
    account: null
};

@StoreConfig({ name: "reset-password", resettable: true })
@Injectable()
export class ResetPasswordStore extends Store<ResetPasswordState> {
    constructor() {
        super(initialState);
    }
}
