import { DatabaseEntities } from "../../common/models/database-entities.model";
import { EscalationLevelAccounts } from "./escalation-level-accounts.model";
import { EscalationLevelTimeout } from "./sub-models/escalation-level-timeout.interface";
import { EscalationLevelRoles } from "./escalation-level-roles.model";

export class EscalationLevels extends DatabaseEntities {
    organizationId?: number;
    urgencyLevelId?: number;
    level?: number;
    timeout?: EscalationLevelTimeout;
    sms?: boolean;
    guardSms?: boolean;
    email?: boolean;
    pushNotification?: boolean;

    escalationLevelRoles?: EscalationLevelRoles[];
    escalationLevelAccounts?: EscalationLevelAccounts[];
}
