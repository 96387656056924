import {
    EquipmentAttributeValidationContext,
    EquipmentAttributeValidationErrors,
    EquipmentAttributeValidator
} from "./equipment-attribute-validator.model";
import { EquipmentAttributeValidatorTypes } from "../equipment-attribute-config.model";
import { combineLatest, defer, EMPTY, Observable, of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { isDefined } from "@recursyve/nice-ts-utils";
import { Type } from "class-transformer";

export class EquipmentAttributeBetweenValidatorOptions {
    public min: number;
    public max: number;
}

export class EquipmentAttributeBetweenValidator extends EquipmentAttributeValidator<EquipmentAttributeBetweenValidatorOptions> {
    public readonly type = EquipmentAttributeValidatorTypes.Between;

    @Type(() => EquipmentAttributeBetweenValidatorOptions)
    public readonly options: EquipmentAttributeBetweenValidatorOptions;

    public override validate(
        value: any,
        context: EquipmentAttributeValidationContext
    ): Observable<EquipmentAttributeValidationErrors> {
        if (!isDefined(value) || Number.isNaN(+value)) {
            return EMPTY;
        }

        return combineLatest({
            min: defer(() => {
                if (
                    context.targetUnitSameAsEquipmentAttribute ||
                    !context.equipmentAttribute?.unitId ||
                    !context?.valueUnitId ||
                    !context.convertUnit
                ) {
                    return of(this.options.min);
                }

                return context.convertUnit(this.options.min, context.equipmentAttribute.unitId, context.valueUnitId);
            }),
            max: defer(() => {
                if (
                    context.targetUnitSameAsEquipmentAttribute ||
                    !context.equipmentAttribute?.unitId ||
                    !context?.valueUnitId ||
                    !context.convertUnit
                ) {
                    return of(this.options.max);
                }

                return context.convertUnit(this.options.max, context.equipmentAttribute.unitId, context.valueUnitId);
            })
        }).pipe(
            filter(({ min, max }) => isDefined(min) && isDefined(max) && !isNaN(+min) && !isNaN(+max)),
            filter(({ min, max }) => value <= min || value >= max),
            map(minMax => ({ equipment_attribute_between: minMax }))
        );
    }
}
