import { Injectable } from "@angular/core";
import { SetupPasswordStore } from "./setup-password.store";
import { catchError, EMPTY, Observable } from "rxjs";
import { ApiService } from "../../../api/api.service";
import { PasswordForm } from "../../components/password-form/password-form.form";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { I18nService } from "../../../providers/i18n.service";
import { niceSetLoadingAndError } from "../../../akita/pipes/nice-set-loading-and-error.pipe";
import { map } from "rxjs/operators";

@Injectable()
export class SetupPasswordService {
    constructor(
        private store: SetupPasswordStore,
        private api: ApiService,
        private toastService: NiceToastService,
        private i18nService: I18nService
    ) {}

    public resetStore(): void {
        this.store.reset();
    }

    public validateCode(code: string): Observable<void> {
        return this.api.setupPassword.getAccountFromUniqueCode(code).pipe(
            niceSetLoadingAndError(this.store),
            map(account => {
                if (account?.language) {
                    this.i18nService.setDefaultLang(account.language);
                }

                this.store.update({
                    account,
                    code
                });
            }),
            catchError(e => {
                this.store.setError(e);
                this.store.update({
                    codeInvalid: true
                });
                return EMPTY;
            })
        );
    }

    public updatePassword(form: PasswordForm): Observable<void> {
        const { code } = this.store.getValue();

        if (!code) {
            return EMPTY;
        }

        return this.api.setupPassword.updateAccountPasswordFromUniqueCode(code, form.toDto()).pipe(
            niceSetLoadingAndError(this.store),
            map(() => {
                this.store.update({ passwordUpdated: true });
                this.toastService.success("features.setup_password.toasts.success");
            }),
            catchError(() => {
                this.toastService.error("features.setup_password.toasts.error");
                return EMPTY;
            })
        );
    }
}
