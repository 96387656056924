import { DatabaseEntities } from "../../common/models/database-entities.model";
import { Type } from "class-transformer";
import { AlertConfigurationValidationFrequency } from "./sub-models/alert-configuration-validation-frequency.interface";
import { AlertConfigurationVariables } from "./sub-models/alert-configuration-variables.interface";
import { AlertConfigurationActivityPeriods } from "./alert-configuration-activity-periods.model";
import { AlertConfigurationValidators } from "./alert-configuration-validators.model";
import { UrgencyLevels } from "../../urgency-levels/models/urgency-levels.model";

export class AlertConfigurations extends DatabaseEntities {
    alertId?: number;
    urgencyLevelId?: number;

    @Type(() => Date)
    startsAt?: Date;

    @Type(() => Date)
    endsAt?: Date;

    @Type(() => AlertConfigurationValidationFrequency)
    validationFrequency?: AlertConfigurationValidationFrequency;

    invalidityIncidentThreshold?: number;

    @Type(() => AlertConfigurationVariables)
    variables?: AlertConfigurationVariables[];

    formula?: string;

    @Type(() => UrgencyLevels)
    urgencyLevel?: UrgencyLevels;

    @Type(() => AlertConfigurationActivityPeriods)
    activityPeriods?: AlertConfigurationActivityPeriods[];

    @Type(() => AlertConfigurationValidators)
    validators?: AlertConfigurationValidators[];
}
