import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, skipWhile } from "rxjs/operators";
import { AppQuery } from "../store/app.query";
import { AppService } from "../store/app.service";

@Injectable({ providedIn: "root" })
export class HomeGuard {
    constructor(private query: AppQuery, private router: Router, private service: AppService) {}

    public canActivate(): Observable<boolean> {
        return this.hasRole();
    }

    private hasRole(): Observable<boolean> {
        return this.query.selectCurrentAccount().pipe(
            skipWhile(account => {
                if (!account) {
                    this.service.loadCurrentAccount().subscribe();
                }
                return !account;
            }),
            map(account => {
                if (!account) {
                    return false;
                }

                if (account.role?.isOrganizationRole && account.organization?.slug) {
                    this.router.navigate(["dashboard", "organization", account.organization.slug]);
                    return false;
                }
                return true;
            })
        );
    }
}
