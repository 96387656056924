import { Injectable } from "@angular/core";
import { OrganizationConnection } from "../api/nordicite/organizations/models/organization-connection.model";
import { ApiService } from "../api/api.service";
import { BehaviorSubject, catchError, Observable, of } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";

export interface UserOrganization extends OrganizationConnection {
    slug: string;
}

const orgSlugStorageKey = "nordicite_user_organization";

@Injectable({ providedIn: "root" })
export class UserOrganizationService {
    public userOrganization$ = new BehaviorSubject<UserOrganization | null>(null);

    constructor(private apiService: ApiService, private window: Window) {}

    public getUserOrganization(): UserOrganization | null {
        return this.userOrganization$.getValue();
    }

    public loadUserOrganization(): Observable<UserOrganization | null> {
        const currentValue = this.getUserOrganization();
        if (currentValue) {
            return of(currentValue);
        }

        try {
            const slug = this.getSlugFromHostname() ?? localStorage.getItem(orgSlugStorageKey);
            if (!slug) {
                return of(null);
            }
            return this.setUserOrganization(slug);
        } catch {
            return of(null);
        }
    }

    public setUserOrganization(slug: string): Observable<UserOrganization | null> {
        return this.apiService.organization.getConnectionFromSlug(slug).pipe(
            map(orgConnection => {
                const userOrganization = { slug, ...orgConnection };
                localStorage.setItem(orgSlugStorageKey, slug);
                this.userOrganization$.next(userOrganization);
                return userOrganization;
            }),
            catchError(() => of(null))
        );
    }

    public clearCurrentOrganization(): void {
        localStorage.removeItem(orgSlugStorageKey);
        this.userOrganization$.next(null);
    }

    private getSlugFromHostname(): string | null {
        if (environment.applicationUrl.includes(this.window.location.hostname)) {
            return null;
        }

        const hostnameSplit = this.window.location.hostname.split(".");
        if (hostnameSplit.length >= 3 && hostnameSplit[0].length >= 1) {
            return hostnameSplit[0];
        }

        return null;
    }
}
