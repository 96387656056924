import { Injectable } from "@angular/core";
import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { lastValueFrom } from "rxjs";
import { EquipmentAttributes } from "./models/equipment-attributes.model";
import { ApiService } from "../../api.service";
import { AttributeConfigTypes } from "../attributes/interfaces/attribute-config.interface";
import { OrArray } from "@datorama/akita";

export interface EquipmentAttributesTypeaheadOptions {
    equipmentId?: number;
    attributeType?: OrArray<AttributeConfigTypes>;
    excludeDisabled?: boolean;
    contextualAlertId?: number;
}

@Injectable()
export class EquipmentAttributesTypeaheadApi extends NiceAsyncTypeaheadProvider<
    EquipmentAttributes,
    number,
    EquipmentAttributesTypeaheadOptions
> {
    public resource = "equipmentAttributes";
    public pageSize = 15;

    constructor(private apiService: ApiService) {
        super();
    }

    public getById(
        equipmentAttributedId: number,
        options?: EquipmentAttributesTypeaheadOptions
    ): Promise<EquipmentAttributes> {
        return lastValueFrom(
            this.apiService.equipmentAttributes.getById(equipmentAttributedId, {
                include: ["unit", "attribute", "attribute.units"],
                ...(options?.contextualAlertId && { contextualAlertId: options.contextualAlertId })
            })
        );
    }

    public async search(
        searchQuery: string,
        page: number,
        options?: EquipmentAttributesTypeaheadOptions
    ): Promise<NiceAsyncTypeaheadSearchResult<EquipmentAttributes>> {
        const items = await lastValueFrom(
            this.apiService.equipmentAttributes.getAll({
                ...options,
                include: ["unit", "attribute", "attribute.units"],
                searchQuery,
                page,
                pageSize: this.pageSize
            })
        );

        return {
            items,
            nextPage: items.length === this.pageSize ? page + 1 : null
        };
    }

    public format(item: EquipmentAttributes): string {
        return item.attribute?.name as string;
    }
}
