import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { AuthProviders } from "../../../../api/nordicite/organizations/models/organizations.model";

export interface SignInAuthenticationState {
    authProvider: AuthProviders | null;
    authConnection: string | null;
    organizationSlug: string | null;
}

const initialValue = {
    authProvider: null,
    authConnection: null,
    organizationSlug: null
};

@Injectable()
@StoreConfig({ name: "sign-in-authentication", resettable: true })
export class SignInAuthenticationStore extends Store<SignInAuthenticationState> {
    constructor() {
        super(initialValue);
    }
}
