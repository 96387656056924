import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { H2labAccountProjects } from "./h2lab-account-projects.model";
import { H2labParameters } from "./h2lab-parameters.model";
import { Equipments } from "../../../equipments/models/equipments.model";
import { EquipmentAttributes } from "../../../equipments/models/equipment-attributes.model";
import { Units } from "../../../units/models/units.model";
import { instanceToInstance, Type } from "class-transformer";
import { isDefined } from "@recursyve/nice-ts-utils";
import { H2labSamples } from "./h2lab-samples.model";

export class H2labDataPoints extends DatabaseEntities {
    accountProjectId?: number;
    equipmentId?: number;
    h2labNetworkId?: number;
    h2labMatrixId?: number;
    networkName?: string;
    matrixName?: string;
    samplingLocation?: string;
    configuredAt?: Date;
    suppressConfigurationNotification?: boolean;

    accountProject?: H2labAccountProjects;
    equipment?: Equipments;

    @Type(() => H2labParameters)
    parameters?: H2labParameters[];
    samples?: H2labSamples[];

    public getPendingConfigurationCount(): number {
        let count = 0;

        if (this.suppressConfigurationNotification) {
            return 0;
        }

        if (!this.configuredAt) {
            count++;
        }

        if (this.parameters) {
            for (const parameter of this.parameters) {
                if (!parameter.configuredAt && !parameter.suppressConfigurationNotification) {
                    count++;
                }
            }
        }

        return count;
    }

    public copyWithResources(resources: {
        equipments: Equipments[];
        equipmentAttributes: EquipmentAttributes[];
        units: Units[];
    }): H2labDataPoints {
        const clone = instanceToInstance(this);
        if (isDefined(this.equipmentId)) {
            clone.equipment = resources.equipments.find(equipment => equipment.id === clone.equipmentId);
        }

        if (clone.parameters?.length) {
            clone.parameters = clone.parameters.map(parameter => parameter.copyWithResources(resources));
        }

        return clone;
    }
}
