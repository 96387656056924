import { LOCATION_INITIALIZED } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from "@angular/material/slide-toggle";
import { MAT_TABS_CONFIG } from "@angular/material/tabs";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouterModule } from "@angular/router";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxFormGeneratorModule } from "@recursyve/ngx-form-generator";
import {
    NiceAdvancedFiltersModule,
    NiceBaseListModule,
    NiceFilterViewModule,
    NiceQueryBuilderModule
} from "@recursyve/nice-data-filter-kit";
import {
    NiceAsyncTypeaheadModule,
    NiceConfigModule,
    NiceModule,
    NiceSweetAlertModule,
    NiceToastModule
} from "@recursyve/nice-ui-kit.v2";
import { environment } from "../environments/environment";
import { ApiModule } from "./api/api.module";
import { AppComponent } from "./app.component";
import { ROUTES } from "./app.routes";
import { DashboardModule } from "./features/dashboard/dashboard.module";
import { GlobalErrorHandler } from "./handlers/global-error.handler";
import { I18nService } from "./providers/i18n.service";
import { AppQuery } from "./store/app.query";
import { AppStore } from "./store/app.store";
import * as Sentry from "@sentry/angular";
import { NiceAuth0Module } from "@recursyve/nice-auth0-kit";
import { AuthenticatedGuard } from "./guards/authenticated.guard";
import { NotAuthenticatedGuard } from "./guards/not-authenticated.guard";
import { PermissionsGuard } from "./guards/permissions.guard";
import { FilterGroupService } from "./providers/filter-group.service";
import { nordiciteBaseListLayout } from "./layouts/base-list.layout";
import { CustomAlertComponent } from "./components/custom-alert/custom-alert.component";
import { CustomAlertModule } from "./components/custom-alert/custom-alert.module";
import { SignInModule } from "./features/sign-in/sign-in.module";
import { HomeGuard } from "./guards/home.guard";
import { SetupPasswordModule } from "./features/setup-password/setup-password.module";
import { ForgotPasswordModule } from "./features/forgot-password/forgot-password.module";
import { ResetPasswordModule } from "./features/reset-password/reset-password.module";
import { lastValueFrom } from "rxjs";
import { OrganizationsTypeaheadApi } from "./api/nordicite/organizations/organizations-typeahead.api";
import { AccountsTypeaheadApi } from "./api/nordicite/accounts/accounts-typeahead.api";
import { NgxPankoModule } from "@recursyve/ngx-panko";
import { NiceBreadcrumbRouteResolver } from "./providers/breadcrumb-route.resolver";
import { AttributesTypeaheadApi } from "./api/nordicite/attributes/attributes-typeahead.api";
import { EquipmentsTypeaheadApi } from "./api/nordicite/equipments/equipments-typeahead.api";
import { UnitsTypeaheadApi } from "./api/nordicite/units/units-typeahead.api";
import { DataSourcesTypeaheadApi } from "./api/nordicite/data-sources/data-source-typeahead.api";
import { EquipmentAttributesTypeaheadApi } from "./api/nordicite/equipments/equipment-attributes-typeahead.api";
import { OrganizationLaboratoriesNavigationItemHintModule } from "./features/dashboard/organizations/laboratories/components/navigation-item-hint/organization-laboratories-navigation-item-hint.module";
import { FlagsmithModule } from "./flagsmith/flagsmith.module";
import { OrganizationReportsNavigationItemHintModule } from "./features/dashboard/organizations/reports/components/navigation-item-hint/organization-reports-navigation-item-hint.module";
import { provideNgxMask } from "ngx-mask";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from "@angular/material/dialog";

export function appInitializerFactory(translate: TranslateService, i18nService: I18nService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(async () => {
                const lang = i18nService.getDefaultLang();
                translate.setDefaultLang(lang);
                try {
                    await lastValueFrom(translate.use(lang));
                } finally {
                    resolve();
                }
            });
        });
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        FlagsmithModule,
        NiceAuth0Module.forRoot(environment.auth0),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES),
        NiceModule,
        NiceConfigModule.forRoot({
            theme: "default",
            scheme: "light"
        }),
        NgxPankoModule.forRoot({
            routeResolver: NiceBreadcrumbRouteResolver,
            icons: {
                home: { svgIcon: "streamline-bold:house-chimney-1" },
                separator: { svgIcon: "streamline-regular:arrow-right" }
            }
        }),
        NiceToastModule.forRoot({
            errorDuration: 6000
        }),
        CustomAlertModule,
        NiceSweetAlertModule.forRoot({
            customModal: CustomAlertComponent
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NiceFilterViewModule.forRoot({
            icons: {
                search: {
                    svgIcon: "streamline-regular:interface-search"
                },
                queryBuilder: {
                    svgIcon: "streamline-regular:filter"
                }
            }
        }),
        NiceBaseListModule.forRoot({
            layout: nordiciteBaseListLayout,
            queryBuilder: {
                filterGroupService: FilterGroupService
            },
            icons: {
                queryBuilder: {
                    svgIcon: "streamline-regular:filter"
                },
                search: {
                    svgIcon: "streamline-regular:interface-search"
                }
            }
        }),
        NiceQueryBuilderModule.forRoot(),
        NiceAdvancedFiltersModule.forRoot({
            filterGroupService: FilterGroupService,
            icons: {
                queryBuilder: {
                    svgIcon: "streamline-regular:filter"
                }
            }
        }),
        NgxFormGeneratorModule.forRoot(),
        NiceAsyncTypeaheadModule.register([
            OrganizationsTypeaheadApi,
            AccountsTypeaheadApi,
            AttributesTypeaheadApi,
            UnitsTypeaheadApi,
            EquipmentsTypeaheadApi,
            DataSourcesTypeaheadApi,
            EquipmentAttributesTypeaheadApi
        ]),
        MatNativeDateModule,
        MatButtonModule,
        MatIconModule,
        ApiModule,
        SignInModule,
        ForgotPasswordModule,
        ResetPasswordModule,
        DashboardModule,
        SetupPasswordModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(), // permet l'utilisation de redux devtools
        AkitaNgRouterStoreModule,
        OrganizationLaboratoriesNavigationItemHintModule,
        OrganizationReportsNavigationItemHintModule
    ],
    providers: [
        AppStore,
        AppQuery,
        I18nService,
        FilterGroupService,
        AuthenticatedGuard,
        NotAuthenticatedGuard,
        PermissionsGuard,
        HomeGuard,
        {
            provide: MatPaginatorIntl,
            useFactory: (i18nService: I18nService) => i18nService.getPaginatorIntl(),
            deps: [I18nService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, I18nService, Injector],
            multi: true
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: Sentry.TraceService, deps: [Router] },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
        },
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: "primary" } },
        { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { color: "primary", hideIcon: true } },
        { provide: MAT_TABS_CONFIG, useValue: { stretchTabs: false } },
        { provide: Window, useValue: window },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                ...new MatDialogConfig(),
                autoFocus: "dialog",
                restoreFocus: false
            }
        },
        provideNgxMask()
    ],
    exports: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {}
