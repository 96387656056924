<div class="bg-lightBlue-500/25 p-8 rounded-[5px]">
    <div class="flex flex-row gap-6">
        <img
            [src]="account.pictureUrl"
            class="w-12 h-12 object-cover rounded-full -mt-0.5"
            nordiciteImageOnErrorFallback
            [fallback]="fallbackProfilePicture"
            alt="account profile picture"
        />

        <ng-template #fallbackProfilePicture>
            <div class="pt-2">
                <mat-icon
                    color="accent"
                    class="scale-[2] min-w-[50px]"
                    svgIcon="streamline-regular:single-neutral-circle"
                ></mat-icon>
            </div>
        </ng-template>
        <div class="flex flex-col">
            <span class="font-bold pb-1">{{ account?.fullName }}</span>
            <h5 class="text-accent pb-4">{{ account?.email }}</h5>
            <span>{{ account?.organization?.name }}</span>
        </div>
    </div>
</div>
