import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { EquipmentAttributes } from "../../../equipments/models/equipment-attributes.model";
import { Units } from "../../../units/models/units.model";
import { instanceToInstance } from "class-transformer";
import { isDefined } from "@recursyve/nice-ts-utils";

export class H2labParameters extends DatabaseEntities {
    dataPointId?: number;
    equipmentAttributeId?: number;
    unitId?: number;
    h2labParameterId?: number;
    name?: string;
    unitName?: string;
    configuredAt?: Date;
    suppressConfigurationNotification?: boolean;

    equipmentAttribute?: EquipmentAttributes;
    unit?: Units;

    public copyWithResources(resources: {
        equipmentAttributes: EquipmentAttributes[];
        units: Units[];
    }): H2labParameters {
        const clone = instanceToInstance(this);
        if (isDefined(clone.equipmentAttributeId)) {
            clone.equipmentAttribute = resources.equipmentAttributes.find(
                equipmentAttribute => equipmentAttribute.id === clone.equipmentAttributeId
            );
        }

        if (isDefined(clone.unitId)) {
            clone.unit = resources.units.find(unit => unit.id === clone.unitId);
        }

        return clone;
    }
}
