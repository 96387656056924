import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { Accounts } from "../../../api/nordicite/accounts/models/accounts.model";

export interface SetupPasswordState {
    account: Accounts | null;
    code: string | null;
    passwordUpdated: boolean;
    codeInvalid: boolean;
}

const initialState: SetupPasswordState = {
    account: null,
    code: null,
    passwordUpdated: false,
    codeInvalid: false
};

@StoreConfig({ name: "setup-password", resettable: true })
@Injectable()
export class SetupPasswordStore extends Store<SetupPasswordState> {
    constructor() {
        super(initialState);
    }
}
