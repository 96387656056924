import { DashboardComponent } from "./features/dashboard/dashboard.component";
import { DASHBOARD_ROUTES } from "./features/dashboard/dashboard.routes";
import { AuthenticatedGuard } from "./guards/authenticated.guard";
import { NotAuthenticatedGuard } from "./guards/not-authenticated.guard";
import { SignInComponent } from "./features/sign-in/sign-in.component";
import { SIGN_IN_ROUTES } from "./features/sign-in/sign-in.route";
import { SetupPasswordComponent } from "./features/setup-password/setup-password.component";
import { ForgotPasswordComponent } from "./features/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./features/reset-password/reset-password.component";
import { NordiciteRoutes } from "./types/route.type";

export const ROUTES: NordiciteRoutes = [
    // Main redirect
    { path: "", redirectTo: "sign-in", pathMatch: "full" },

    {
        path: "sign-in",
        component: SignInComponent,
        children: SIGN_IN_ROUTES,
        canActivate: [NotAuthenticatedGuard]
    },
    {
        path: "dashboard",
        component: DashboardComponent,
        children: DASHBOARD_ROUTES,
        canActivate: [AuthenticatedGuard]
    },
    {
        path: "setup-password/:code",
        component: SetupPasswordComponent
    },
    {
        path: "forgot-password",
        component: ForgotPasswordComponent
    },
    {
        path: "reset-password/:code",
        component: ResetPasswordComponent
    }
];
