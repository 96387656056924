import { DatabaseEntities } from "../../common/models/database-entities.model";
import { Accounts } from "../../accounts/models/accounts.model";
import { UrgencyLevels } from "../../urgency-levels/models/urgency-levels.model";
import { IncidentEvents } from "./incident-events.model";
import { IncidentStatuses } from "../enums/incident-statuses.enum";
import { Transform, Type } from "class-transformer";
import { isNotNullOrUndefined } from "@recursyve/nice-ui-kit.v2";
import { isArray } from "class-validator";
import { AlertConfigurationValidationFailures } from "../../alerts/models/alert-configuration-validation-failures.model";

export class Incidents extends DatabaseEntities {
    organizationId: number;
    urgencyLevelId: number;
    assignedToAccountId?: number;

    number?: string;
    status?: IncidentStatuses;
    title?: string;
    description?: string;
    alertConfigurationValidationFailure?: AlertConfigurationValidationFailures;
    assignedToAccount?: Accounts;
    urgencyLevel?: UrgencyLevels;

    @Type(() => IncidentEvents)
    events?: IncidentEvents[];

    @Transform(({ value }) => {
        if (isArray(value)) {
            return value[0];
        }
        return value;
    })
    latestEvent: IncidentEvents;

    public get formattedNumber(): string {
        if (isNotNullOrUndefined(this.number)) {
            return "#" + this.number?.padStart(5, "0");
        }
        return "";
    }
}
