import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { SetupPasswordStore, SetupPasswordState } from "./setup-password.store";
import { Observable } from "rxjs";
import { Accounts } from "../../../api/nordicite/accounts/models/accounts.model";

@Injectable()
export class SetupPasswordQuery extends Query<SetupPasswordState> {
    constructor(store: SetupPasswordStore) {
        super(store);
    }

    public selectAccount(): Observable<Accounts | null> {
        return this.select(s => s.account);
    }

    public selectCodeInvalid(): Observable<boolean> {
        return this.select(s => s.codeInvalid);
    }

    public selectPasswordUpdated(): Observable<boolean> {
        return this.select(s => s.passwordUpdated);
    }
}
