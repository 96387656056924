import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NordiciteBaseApi } from "../../nordicite-base.api";
import { Observable } from "rxjs";
import { HealthAndSafetyForms } from "../models/health-and-safety-forms.model";

@Injectable()
export class HealthAndSafetyFormsApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("health-and-safety/form");
    }

    public getAllHealthAndSafetyForms(): Observable<HealthAndSafetyForms[]> {
        return this.http.get<HealthAndSafetyForms[]>(this.url());
    }
}
