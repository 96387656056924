import { SignInAuthenticationState, SignInAuthenticationStore } from "./sign-in-authentication.store";
import { Query } from "@datorama/akita";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class SignInAuthenticationQuery extends Query<SignInAuthenticationState> {
    constructor(protected store: SignInAuthenticationStore) {
        super(store);
    }

    public selectAuthProvider(): Observable<string | null> {
        return this.select("authProvider");
    }
}
