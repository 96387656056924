import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { EurofinsDataPoints } from "./eurofins-data-points.model";
import { Type } from "class-transformer";

export class EurofinsPurchaseOrders extends DatabaseEntities {
    laboratoryId?: number;
    disabledByAccountId?: number;
    number?: string;
    disabledAt?: Date;
    metadataSynchronizedUntil?: Date;
    dataSynchronizedUntil?: Date;

    @Type(() => EurofinsDataPoints)
    dataPoints?: EurofinsDataPoints[];

    public getPendingConfigurationCount(): number {
        if (!this.dataPoints?.length || this.disabledAt) {
            return 0;
        }

        return this.dataPoints.reduce(
            (pendingConfigurationCount, dataPoint) =>
                pendingConfigurationCount + dataPoint.getPendingConfigurationCount(),
            0
        );
    }
}
