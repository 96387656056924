import { EquipmentAttributeValidatorTypes } from "../equipment-attribute-config.model";
import { EquipmentAttributes } from "../equipment-attributes.model";
import { OrganizationData } from "../../../data-entries/models/organization-data.model";
import { Observable } from "rxjs";

export type EquipmentAttributeValidationErrors = Record<string, Record<string, any>>;

export type ConvertUnit = (value: any, sourceUnitId: number, targetUnitId: number) => Observable<any>;

export type RetrievePreviousData = () => Observable<OrganizationData | null | undefined>;

export abstract class EquipmentAttributeValidator<OPTS = any> {
    public readonly type?: EquipmentAttributeValidatorTypes;
    public readonly options?: OPTS;

    public abstract validate(
        value: any,
        context: EquipmentAttributeValidationContext
    ): Observable<EquipmentAttributeValidationErrors>;
}

export class EquipmentAttributeValidationContext {
    public valueUnitId?: number;
    public equipmentAttribute?: EquipmentAttributes;

    public convertUnit?: ConvertUnit;
    public retrievePreviousData?: RetrievePreviousData;

    public get targetUnitSameAsEquipmentAttribute(): boolean {
        return !this.equipmentAttribute?.unitId || this.valueUnitId === this.equipmentAttribute.unitId;
    }

    constructor(value: Omit<EquipmentAttributeValidationContext, "targetUnitSameAsEquipmentAttribute">) {
        Object.assign(this, value);
    }
}
