import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { environment } from "../../environments/environment";
import * as Sentry from "@sentry/angular";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private sentry: ErrorHandler;

    constructor(private notificationService: NiceToastService) {
        this.sentry = Sentry.createErrorHandler({
            logErrors: true
        });
    }

    public handleError(error: Error | HttpErrorResponse) {
        let message = error.message;
        if (environment.production) {
            message = "handler.prod_message";
        }

        this.notificationService.error(message);

        return this.sentry.handleError(error);
    }
}
