import { Injectable } from "@angular/core";
import { filterNilValue, Query } from "@datorama/akita";
import { Observable } from "rxjs";
import { AppState, AppStore } from "./app.store";
import { Accounts } from "../api/nordicite/accounts/models/accounts.model";
import { ToolbarItem } from "../features/dashboard/components/toolbar/toolbar.item";
import { Organizations } from "../api/nordicite/organizations/models/organizations.model";
import { map } from "rxjs/operators";
import { OrganizationLaboratoriesConfigurationStatus } from "../api/nordicite/laboratories/types/organization-laboratories-configuration-status.type";
import { ReportsSignatureStatus } from "../api/nordicite/reports/interfaces/reports-signature-status.interface";

@Injectable()
export class AppQuery extends Query<AppState> {
    constructor(protected store: AppStore) {
        super(store);
    }

    public selectCurrentAccount(): Observable<Accounts | null | undefined> {
        return this.select("currentAccount");
    }

    public selectUserHasMultipleOrganizations(): Observable<boolean> {
        return this.select("userHasMultipleOrganizations");
    }

    public selectCurrentToolbarItem(): Observable<ToolbarItem | null | undefined> {
        return this.select("currentToolbarItem");
    }

    public selectCurrentOrganization(): Observable<Organizations | null | undefined> {
        return this.select("currentOrganization");
    }

    public selectCurrentOrganizationLaboratoriesConfigurationStatus(): Observable<
        OrganizationLaboratoriesConfigurationStatus | null | undefined
    > {
        return this.select("currentOrganizationLaboratoriesConfigurationStatus");
    }

    public selectShouldShowNav(): Observable<boolean> {
        return this.select("shouldShowNav");
    }

    public selectAccountHasPermission(permission: string): Observable<boolean> {
        return this.selectCurrentAccount().pipe(
            filterNilValue(),
            map(account => account.permissions?.includes(permission) ?? false)
        );
    }

    public selectAccountHasSomePermission(permissions: string[]): Observable<boolean> {
        return this.selectCurrentAccount().pipe(
            filterNilValue(),
            map(account => permissions.some(permission => account.permissions?.includes(permission)))
        );
    }

    public selectCurrentOrganizationReportsSignatureStatus(): Observable<ReportsSignatureStatus[]> {
        return this.select("currentOrganizationReportsSignatureStatus");
    }
}
