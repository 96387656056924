import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ResetPasswordService } from "./store/reset-password.service";
import { ResetPasswordQuery } from "./store/reset-password.query";
import { PasswordForm } from "../components/password-form/password-form.form";
import { filter, take } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs";

@Component({
    selector: "nordicite-reset-password",
    templateUrl: "./reset-password.template.html",
    styleUrls: ["./reset-password.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
    public loading$ = this.query.selectLoading();
    public codeInvalid$ = this.query.selectCodeInvalid();
    public passwordUpdated$ = this.query.selectPasswordUpdated();

    constructor(
        private service: ResetPasswordService,
        private query: ResetPasswordQuery,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.activatedRoute.paramMap
            .pipe(
                take(1),
                filter(params => params.has("code")),
                switchMap(params => this.service.getAccountFromUniqueCode(params.get("code") as string))
            )
            .subscribe();
    }

    public submit(form: PasswordForm): void {
        this.service.updatePassword(form).subscribe();
    }

    public backToLogin(): void {
        this.router.navigate(["/sign-in"]);
        this.service.resetStore();
    }
}
