import {
    EquipmentAttributeValidationContext,
    EquipmentAttributeValidationErrors,
    EquipmentAttributeValidator
} from "./equipment-attribute-validator.model";
import { EquipmentAttributeValidatorTypes } from "../equipment-attribute-config.model";
import { combineLatest, defer, EMPTY, Observable, of, switchMap } from "rxjs";
import { MathUtils } from "../../../../../utils/math.utils";
import { filter, map } from "rxjs/operators";
import { isDefined } from "@recursyve/nice-ts-utils";
import { Type } from "class-transformer";

export class EquipmentAttributeDeltaLessThanValidatorOptions {
    public max: number;
}

export class EquipmentAttributeDeltaLessThanValidator extends EquipmentAttributeValidator<EquipmentAttributeDeltaLessThanValidatorOptions> {
    public readonly type = EquipmentAttributeValidatorTypes.DeltaLessThan;

    @Type(() => EquipmentAttributeDeltaLessThanValidatorOptions)
    public readonly options: EquipmentAttributeDeltaLessThanValidatorOptions;

    public override validate(
        value: any,
        context: EquipmentAttributeValidationContext
    ): Observable<EquipmentAttributeValidationErrors> {
        if (!isDefined(value) || isNaN(+value)) {
            return EMPTY;
        }

        return combineLatest({
            max: defer(() => {
                if (
                    context.targetUnitSameAsEquipmentAttribute ||
                    !context.equipmentAttribute?.unitId ||
                    !context?.valueUnitId ||
                    !context.convertUnit
                ) {
                    return of(this.options.max);
                }

                return context.convertUnit(this.options.max, context.equipmentAttribute.unitId, context.valueUnitId);
            }),
            previousEntry: context.retrievePreviousData ? context.retrievePreviousData() : EMPTY
        }).pipe(
            filter(
                ({ max, previousEntry }) =>
                    isDefined(max) &&
                    !isNaN(+max) &&
                    !!previousEntry &&
                    isDefined(previousEntry?.value) &&
                    !isNaN(+previousEntry.value) &&
                    isDefined(context.equipmentAttribute?.attribute?.storageUnitId)
            ),
            switchMap(({ max, previousEntry }) => {
                if (context.valueUnitId === context.equipmentAttribute?.attribute?.storageUnitId) {
                    return of({
                        max,
                        previousValue: previousEntry?.value
                    });
                }

                if (
                    !context.convertUnit ||
                    !context.equipmentAttribute?.attribute?.storageUnitId ||
                    !context.valueUnitId
                ) {
                    return EMPTY;
                }

                return context
                    .convertUnit(
                        previousEntry?.value,
                        context.equipmentAttribute.attribute.storageUnitId,
                        context.valueUnitId
                    )
                    .pipe(map(previousValue => ({ max, previousValue })));
            }),
            filter(({ previousValue }) => isDefined(previousValue) && !isNaN(+previousValue)),
            map(({ max, previousValue }) => ({
                max,
                delta: MathUtils.roundToDecimalPlaces(Math.abs(value - previousValue), 2)
            })),
            filter(({ max, delta }) => delta >= max),
            map(args => ({ equipment_attribute_delta_less_than: args }))
        );
    }
}
