import { NiceNavigationItem } from "@recursyve/nice-ui-kit.v2";
import { Accounts } from "../api/nordicite/accounts/models/accounts.model";
import { Organizations } from "../api/nordicite/organizations/models/organizations.model";
import { OrganizationLaboratoriesNavigationItemHintResolver } from "../features/dashboard/organizations/laboratories/components/navigation-item-hint/organization-laboratories-navigation-item-hint.resolver";
import { OrganizationLaboratoriesNavigationItemHintComponent } from "../features/dashboard/organizations/laboratories/components/navigation-item-hint/organization-laboratories-navigation-item-hint.component";
import { FlagsmithFeatureFlagNavigationResolver } from "../flagsmith/providers/flagsmith-feature-flag-navigation.resolver";
import { OrganizationReportsNavigationItemHintResolver } from "../features/dashboard/organizations/reports/components/navigation-item-hint/organization-reports-navigation-item-hint.resolver";
import { OrganizationReportsNavigationItemHintComponent } from "../features/dashboard/organizations/reports/components/navigation-item-hint/organization-reports-navigation-item-hint.component";
import { OrganizationSettingsNavigationItemHintResolver } from "../features/dashboard/organizations/settings/components/navigation-item-hint/organization-settings-navigation-item-hint.resolver";
import { OrganizationSettingsNavigationItemHintComponent } from "../features/dashboard/organizations/settings/components/navigation-item-hint/organization-settings-navigation-item-hint.component";

export interface CustomNavigationItem extends NiceNavigationItem {
    permission?: string;
    children?: (NiceNavigationItem & { permission?: string })[];
}

export const navigationItems = (account: Accounts, organization?: Organizations | null): CustomNavigationItem[] => {
    if (!organization?.slug) {
        return [];
    }

    const slug = organization.slug;

    return [
        {
            id: "organization-dashboard",
            title: "navigation.organization_dashboard",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:layout-dashboard",
            link: `/dashboard/organization/${slug}/dashboard`,
            permission: "nordicite:read:organization-dashboard",
            hidden: FlagsmithFeatureFlagNavigationResolver,
            meta: {
                featureKey: "indicators"
            }
        },
        {
            id: "data-source",
            title: "navigation.data_sources",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:folder",
            link: `/dashboard/organization/${slug}/data-source`,
            permission: "nordicite:read:data-sources"
        },
        {
            id: "report",
            title: "navigation.reports",
            type: "basic",
            link: `/dashboard/organization/${slug}/report`,
            permission: "nordicite:read:reports",
            icon: "streamline-regular:workflow-data-table",
            iconType: "svg",
            hint: {
                resolver: OrganizationReportsNavigationItemHintResolver,
                component: OrganizationReportsNavigationItemHintComponent
            },
            hidden: FlagsmithFeatureFlagNavigationResolver,
            meta: {
                featureKey: "reports"
            }
        },
        {
            id: "report",
            title: "navigation.alerts",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:alert-bell-notification-1",
            link: `/dashboard/organization/${slug}/alert`,
            permission: "nordicite:read:alerts",
            hidden: FlagsmithFeatureFlagNavigationResolver,
            meta: {
                featureKey: "alerts"
            }
        },
        {
            id: "incidents",
            title: "navigation.incidents",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:alert-triangle",
            link: `/dashboard/organization/${slug}/incident`,
            permission: "nordicite:read:incidents",
            hidden: FlagsmithFeatureFlagNavigationResolver,
            meta: {
                featureKey: "incidents"
            }
        },
        {
            id: "mandate",
            title: "navigation.mandates",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:pen-write-paper",
            link: `/dashboard/organization/${slug}/mandate`,
            permission: "nordicite:read:mandates",
            hidden: _ => !!account.organizationId && account.organizationId !== organization.id
        },
        {
            id: "organization-laboratories",
            title: "navigation.organization_laboratories",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:lab-flask-experiment",
            link: `/dashboard/organization/${slug}/laboratory`,
            permission: "nordicite:read:organization-laboratories",
            hint: {
                resolver: OrganizationLaboratoriesNavigationItemHintResolver,
                component: OrganizationLaboratoriesNavigationItemHintComponent
            }
        },
        {
            id: "journal",
            title: "navigation.journals",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:book-next-page",
            link: `/dashboard/organization/${slug}/journal`,
            permission: "nordicite:read:journals"
        },
        {
            id: "health-and-safety",
            title: "navigation.health_and_safety",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:hospital-board",
            link: `/dashboard/organization/${slug}/health-and-safety`,
            permission: "nordicite:read:health-and-safety-records"
        },
        {
            id: "user",
            title: "navigation.users",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:multiple-neutral-2",
            link: `/dashboard/organization/${slug}/user`,
            permission: "nordicite:read:organization-accounts-all",
            hidden: _ => !!account.organizationId && account.organizationId !== organization.id
        },
        {
            id: "settings",
            title: "navigation.settings",
            type: "basic",
            iconType: "svg",
            icon: "streamline-regular:cog-double-2",
            link: `/dashboard/organization/${slug}/settings`,
            permission: "nordicite:read:organization-settings",
            hidden: FlagsmithFeatureFlagNavigationResolver,
            meta: {
                featureKey: "organization_settings"
            },
            hint: {
                resolver: OrganizationSettingsNavigationItemHintResolver,
                component: OrganizationSettingsNavigationItemHintComponent
            }
        }
    ];
};
