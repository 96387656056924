import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { filterNilValue } from "@datorama/akita";
import { isDefined } from "@recursyve/nice-ts-utils";
import { filter, takeUntil, tap } from "rxjs/operators";
import { AppQuery } from "../store/app.query";
import { UnsubscribeAllMixin } from "../mixins/unsubscribe-all.mixin";

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: "[ifSomePermission]" })
export class IfSomePermissionDirective extends UnsubscribeAllMixin() implements OnInit, OnDestroy {
    private _permissions: string[] = [];

    @Input("ifSomePermission")
    public set permissions(permissions: string | string[]) {
        if (!Array.isArray(permissions)) {
            this._permissions = [permissions];
        } else {
            this._permissions = permissions;
        }
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private appQuery: AppQuery
    ) {
        super();
    }

    public ngOnInit(): void {
        this.appQuery
            .selectCurrentAccount()
            .pipe(
                takeUntil(this.unsubscribeAll$),
                tap(() => this.viewContainer.clear()),
                filterNilValue(),
                filter(
                    user =>
                        isDefined(user.permissions) &&
                        this._permissions.some(permission => user.permissions?.includes(permission))
                )
            )
            .subscribe(() => {
                this.viewContainer.createEmbeddedView(this.templateRef);
            });
    }
}
