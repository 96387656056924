import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { AppQuery } from "./app.query";
import { AppStore } from "./app.store";
import {
    catchError,
    combineLatest,
    defaultIfEmpty,
    defer,
    delayWhen,
    distinctUntilChanged,
    EMPTY,
    from,
    Observable,
    of,
    switchMap,
    tap
} from "rxjs";
import { Accounts } from "../api/nordicite/accounts/models/accounts.model";
import { NiceAuthenticationService } from "@recursyve/nice-auth0-kit";
import { ToolbarItem } from "../features/dashboard/components/toolbar/toolbar.item";
import { Organizations } from "../api/nordicite/organizations/models/organizations.model";
import { UserOrganizationService } from "../providers/user-organization.service";
import { I18nService } from "../providers/i18n.service";
import { niceSetError } from "../akita/pipes/nice-set-error.pipe";
import { FlagsmithService } from "../flagsmith/services/flagsmith.service";
import { OrganizationLaboratoriesConfigurationStatus } from "../api/nordicite/laboratories/types/organization-laboratories-configuration-status.type";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { niceSetLoadingAndError } from "../akita/pipes/nice-set-loading-and-error.pipe";
import { filter, map } from "rxjs/operators";

@UntilDestroy()
@Injectable({
    providedIn: "root"
})
export class AppService {
    constructor(
        private store: AppStore,
        private query: AppQuery,
        private api: ApiService,
        private authService: NiceAuthenticationService,
        private userOrganizationService: UserOrganizationService,
        private i18nService: I18nService,
        private flagsmithService: FlagsmithService
    ) {}

    public init(): void {
        this.query
            .selectCurrentOrganization()
            .pipe(
                distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
                switchMap(currentOrganization => {
                    if (!currentOrganization?.id) {
                        this.store.update({
                            currentOrganizationReportsSignatureStatus: [],
                            currentOrganizationLaboratoriesConfigurationStatus: {
                                pendingOrganizationLaboratoryConfigurationCount: 0
                            }
                        });
                        return EMPTY;
                    }

                    return combineLatest([
                        this.api.organization
                            .reports(currentOrganization.id)
                            .getOrganizationReportsSignatureStatus()
                            .pipe(
                                niceSetError(this.store),
                                catchError(() => of(null))
                            ),
                        this.getOrganizationLaboratoryConfigurationStatusIfPermitted(currentOrganization.id).pipe(
                            defaultIfEmpty(null)
                        )
                    ]);
                }),
                untilDestroyed(this)
            )
            .subscribe(
                ([currentOrganizationReportsSignatureStatus, currentOrganizationLaboratoriesConfigurationStatus]) => {
                    // @ts-ignore
                    this.store.update({
                        currentOrganizationLaboratoriesConfigurationStatus,
                        currentOrganizationReportsSignatureStatus
                    });
                }
            );
    }

    public loadServerState(): Observable<void> {
        return this.api.info.getInfo().pipe(
            niceSetLoadingAndError(this.store),
            map(serverState => this.store.update({ serverState }))
        );
    }

    public loadCurrentAccount(): Observable<Accounts | null> {
        return defer(() => {
            const { currentAccount } = this.store.getValue();
            if (currentAccount) {
                return of(currentAccount);
            }
            return this.api.account.getInfo().pipe(
                tap(account => {
                    if (account?.language) {
                        this.i18nService.setDefaultLang(account.language);
                    }

                    this.store.update({
                        currentAccount: account
                    });
                }),
                delayWhen(account => {
                    if (!account.userId) {
                        return EMPTY;
                    }

                    return from(this.flagsmithService.identify(account.userId));
                }),
                catchError(e => {
                    if (e?.status === 404) {
                        return of(null);
                    }

                    this.store.setError(e);
                    return EMPTY;
                })
            );
        });
    }

    public loadUserHasMultipleOrganizations(): Observable<void> {
        return this.api.organization
            .search({
                pageSize: 2,
                page: 0,
                fromAccount: true
            })
            .pipe(
                niceSetLoadingAndError(this.store),
                map(organizations => this.store.update({ userHasMultipleOrganizations: organizations.length >= 2 }))
            );
    }

    public logout(): Observable<void> {
        return this.authService.logout().pipe(
            tap(() => {
                this.userOrganizationService.clearCurrentOrganization();
                this.store.reset();
            }),
            delayWhen(() => from(this.flagsmithService.logout()))
        );
    }

    public updateCurrentToolbarItem(currentToolbarItem: ToolbarItem): void {
        this.store.update({
            currentToolbarItem,
            currentOrganization: null,
            currentOrganizationLaboratoriesConfigurationStatus: null
        });
    }

    public updateCurrentOrganization(currentOrganization: Organizations): void {
        this.store.update({ currentOrganization });
    }

    public updateCurrentOrganizationById(organizationId: number): Observable<Organizations> {
        return this.api.organization.getById(organizationId).pipe(
            niceSetLoadingAndError(this.store),
            filter(organization => !!organization),
            tap(organization => {
                this.store.update({ currentOrganization: organization });
            })
        );
    }

    public updateShouldShowNav(shouldShowNav: boolean): void {
        this.store.update({ shouldShowNav });
    }

    // TODO we will need to implements this but for ReportsSignatureStatus when a report will be signed, to update nav.
    public reloadCurrentOrganizationLaboratoryConfigurationStatus(): void {
        const { currentOrganization } = this.query.getValue();
        if (!currentOrganization?.id) {
            return;
        }

        this.getOrganizationLaboratoryConfigurationStatusIfPermitted(currentOrganization.id).subscribe(
            organizationLaboratoriesConfigurationStatus => {
                this.store.update({
                    currentOrganizationLaboratoriesConfigurationStatus: organizationLaboratoriesConfigurationStatus
                });
            }
        );
    }

    private getOrganizationLaboratoryConfigurationStatusIfPermitted(
        organizationId?: number
    ): Observable<OrganizationLaboratoriesConfigurationStatus> {
        return defer(() => {
            if (
                !this.query.getValue().currentAccount?.permissions?.includes("nordicite:read:organization-laboratories")
            ) {
                return EMPTY;
            }

            if (!organizationId) {
                return EMPTY;
            }

            return this.api.organizationLaboratories.getConfigurationStatus({ organizationId }).pipe(
                niceSetError(this.store),
                catchError(() => EMPTY)
            );
        });
    }

    public setCurrentOrganizationGuardSms(guardSms: string[]): void {
        this.store.update(state =>
            state.currentOrganization
                ? {
                      currentOrganization: {
                          ...state.currentOrganization,
                          guardSms
                      }
                  }
                : undefined
        );
    }
}
