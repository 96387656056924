import { DatabaseEntities } from "../../common/models/database-entities.model";
import { IndicatorTypes } from "../enums/indicator-types.enum";
import { IndicatorTypeConfig } from "../interfaces/indicator-type-config.interface";
import { IndicatorsConstants } from "../../../../features/dashboard/organizations/indicators/constants/indicators.constants";
import { plainToInstance, Transform, Type } from "class-transformer";
import { isDefined } from "@recursyve/nice-ts-utils";
import { IndicatorGraphTypeConfig } from "../interfaces/indicator-graph-type-config.interface";
import { IndicatorGroupTypeConfig } from "../interfaces/indicator-group-type-config.interface";
import { IndicatorSeparatorTypeConfig } from "../interfaces/indicator-separator-type-config.interface";
import { IndicatorSimpleValueTypeConfig } from "../interfaces/indicator-simple-value-type-config.interface";
import { IndicatorRingTypeConfig } from "../interfaces/indicator-ring-type-config.interface";
import { IndicatorLevelTypeConfig } from "../interfaces/indicator-level-type-config.interface";

export class Indicators<T extends IndicatorTypeConfig = IndicatorTypeConfig> extends DatabaseEntities {
    indicatorViewId?: number;
    parentIndicatorId?: number;
    name?: string;
    positionX?: number;
    positionY?: number;

    type?: IndicatorTypes;

    @Transform(({ value, obj }: { value: T; obj: Indicators<T> }) => {
        switch (obj.type) {
            case IndicatorTypes.Group:
                return plainToInstance(IndicatorGroupTypeConfig, value);
            case IndicatorTypes.Graph:
                return plainToInstance(IndicatorGraphTypeConfig, value);
            case IndicatorTypes.Separator:
                return plainToInstance(IndicatorSeparatorTypeConfig, value);
            case IndicatorTypes.SimpleValue:
                return plainToInstance(IndicatorSimpleValueTypeConfig, value);
            case IndicatorTypes.Ring:
                return plainToInstance(IndicatorRingTypeConfig, value);
            case IndicatorTypes.Level:
                return plainToInstance(IndicatorLevelTypeConfig, value);
            default:
                return value;
        }
    })
    typeConfig: T;

    @Type(() => Indicators)
    childIndicators: Indicators[];

    public get width(): number {
        if (!this.type) {
            return 0;
        }

        return IndicatorsConstants.getTypeWidth(this.type) + this.childIndicatorsWidth;
    }

    public get height(): number {
        if (!this.type) {
            return 0;
        }

        return IndicatorsConstants.getTypeHeight(this.type) + this.childIndicatorsHeight;
    }

    public get childIndicatorsWidth(): number {
        if (!this.childIndicators?.length) {
            return 0;
        }

        return this.childIndicators
            .map(indicator => indicator?.positionX ?? indicator.width)
            .reduce((furthest, current) => (current > furthest ? current : furthest));
    }

    public get childIndicatorsHeight(): number {
        if (!this.childIndicators?.length) {
            return 0;
        }

        return this.childIndicators
            .map(indicator => indicator?.positionY ?? indicator.height)
            .reduce((furthest, current) => (current > furthest ? current : furthest));
    }

    public get maxChildIndicatorsWidth(): number {
        if (!this.type) {
            return 0;
        }

        return IndicatorsConstants.viewGridWidth - (this.positionX ?? 0) - IndicatorsConstants.getTypeWidth(this.type);
    }

    public upsertIndicatorInPlace(upsertedIndicator: Partial<Indicators> & Pick<Indicators, "id">): void {
        for (let i = (this.childIndicators?.length ?? 0) - 1; i >= 0; i--) {
            const indicator = this.childIndicators[i];

            if (indicator.id === upsertedIndicator.id) {
                if (isDefined(upsertedIndicator.parentIndicatorId) && upsertedIndicator.id !== this.id) {
                    this.childIndicators.splice(i, 1);
                } else {
                    Object.assign(indicator, upsertedIndicator);
                }
            }

            indicator.upsertIndicatorInPlace(upsertedIndicator);
        }

        if (upsertedIndicator.parentIndicatorId === this.id) {
            (this.childIndicators ??= []).push(upsertedIndicator as Indicators);
        }
    }

    public deleteIndicator(deletedIndicator: Partial<Indicators> & Pick<Indicators, "id">): boolean {
        if (!this.childIndicators) {
            return false;
        }
        for (let index = 0; index < this.childIndicators.length; index++) {
            if (this.childIndicators[index].id === deletedIndicator.id) {
                this.childIndicators.splice(index, 1);
                return true;
            }

            if (this.childIndicators[index].deleteIndicator(deletedIndicator)) {
                return true;
            }
        }

        return false;
    }
}
