import { Injectable } from "@angular/core";
import { BreadcrumbItems, BreadcrumbRouteResolver, ResourceBreadcrumbItems } from "@recursyve/ngx-panko";
import * as inflection from "inflection";
import { AppQuery } from "../store/app.query";
import { LinkBreadcrumbItems } from "@recursyve/ngx-panko/lib/breadcrumb/models/breadcrumb-items.model";

@Injectable()
export class NiceBreadcrumbRouteResolver extends BreadcrumbRouteResolver {
    constructor(private appQuery: AppQuery) {
        super();
    }

    public getItemLink(currentItem: BreadcrumbItems | null): any[] {
        if (!currentItem) {
            return ["/dashboard"];
        }

        const path: any[] = ["/dashboard"];
        const { currentOrganization } = this.appQuery.getValue();
        if (currentOrganization) {
            path.push("organization", currentOrganization?.slug);
        }

        if ((currentItem as LinkBreadcrumbItems).link) {
            const item = currentItem as LinkBreadcrumbItems;
            if (Array.isArray(item.link)) {
                path.push(...item.link);
            } else {
                path.push(item.link);
            }
        } else {
            const item = currentItem as ResourceBreadcrumbItems;
            if (item.resourceName) {
                path.push(inflection.singularize(inflection.dasherize(item.resourceName)));
            }
            if (item.resourceId) {
                path.push(item.resourceId);
            }
        }

        return path;
    }
}
